export const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    height: "2.5rem",
    mt: 2,
    cursor: "pointer",
  },
  title: {
    textAlign: "left",
    lineHeight: "1rem",
    ml: 2,
    mr: 3,
    width: "10.25rem",
    "::before": {
      opacity: 0,
    },
    boxShadow: "0",
  },
  statusBar: {
    height: "100%",
    width: "0.25rem",
  },
  serialText: {
    borderLeft: "1px solid #E6E6E6 ",
    width: "33rem",
    pl: 3,
    mr: 3,
  },
  text: {
    px: 2,
  },
  iconTextBrake: {
    display: "flex",
    alignItems: "right",
    fontSize: "0.875rem",
    // width: "10.875rem",
    pl: 3,
    borderLeft: "1px solid #E6E6E6 ",
  },
  iconTextAssets: {
    display: "flex",

    alignItems: "center",
    fontSize: "0.875rem",
    width: "10.875rem",
    pl: 3,
    borderLeft: "1px solid #E6E6E6 ",
  },
  icon: {
    fontSize: "1.25rem",
  },
  warningIcon: {
    fontSize: "1.25rem",
    color: "#0067E0",
    position: "absolute",
    right: "2%",
  },
}
