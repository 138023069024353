import { DEFAULT_SPACING_FACTOR } from "core/constants/ui";

import { Theme, useTheme } from "@mui/material/styles";

const DURATION_OFFSET = 100

const styles = (theme: Theme = useTheme()) => ({
  root: {
    width: "100%",
  },
  cardsContainer: {
    mt: 4,
    flexWrap: "wrap",
    display: "flex",
    flexDirection: "row",
  },
  gridTransition: {
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),
  },
  drawerOpen: {
    width: "auto",
    height: "auto",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
  },
  drawerClose: {
    width: "1px",
    height: "1px",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard - DURATION_OFFSET,
    }),
  },

  rootDetailOpen: {
    width: `calc(100% - ${200}px)`,
  },
  gridContainer: {
    maxHeight: "100%",
  },
  infiniteScroll: {
    margin: 15,
    display: "flex",
    alignItems: "center",
    marginBottom: 20,
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  assetsRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    height: "100%",
    marginTop: 20 / DEFAULT_SPACING_FACTOR,
    marginBottom: 20 / DEFAULT_SPACING_FACTOR,
    [theme.breakpoints.up("xl")]: {
      justifyContent: "center",
    },
  },
  cardRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 20 / DEFAULT_SPACING_FACTOR,
    [theme.breakpoints.up("xl")]: {
      justifyContent: "center",
    },
  },
})

export default styles
