import { Box, IconButton, Skeleton } from "@mui/material"
import {
  getDepartmentsSelector,
  useGetDepartmentInfoQuery,
} from "store/Department/departmentSlice"
import { useDispatch, useSelector } from "react-redux"

import AppTypography from "core/components/AppTypography"
import { Close } from "@mui/icons-material"
import { IProductionLine } from "models/interfaces/productionLine"
import { MachineCollection } from "models/organization"
import { useNavigate } from "react-router-dom"
import React from "react"
import { RootState } from "store/store"
import { replaceUnderscores } from "core/helpers"
import { setIsDrawerDetailsOpen } from "store/rootSlice"
import { styles } from "./styles"
import { useGetMachineCardQuery } from "store/Machines/machinesSlice"
import { useGetProductionLineInfoQuery } from "store/Production_lines/productionLineSlice"

const HeaderDetailView = () => {
  const navigate = useNavigate()
  const machinesState = (state: RootState) => state.machines
  const { machineSelected } = useSelector(machinesState)
  const { machine_id: machineId = "" } = machineSelected

  const siteState = (state: RootState) => state.sites
  const { selectedSiteId: siteUUID = "" } = useSelector(siteState)

  const { machineName, productionLineId = "" } = useGetMachineCardQuery(
    { machineId: machineId ?? "" },
    {
      selectFromResult: ({ data }) => ({
        machineName: data?.machine_name.replace("_", " ") ?? "",
        productionLineId: data?.production_line_id,
      }),
      skip: !machineId && !siteUUID,
    },
  )

  const departments = useSelector((state: RootState) =>
    getDepartmentsSelector(state, siteUUID ?? ""),
  ).find(({ production_line_collection }: any) =>
    production_line_collection.find(
      ({ production_line_id: id }: any) => id === productionLineId,
    ),
  )

  const prodId = departments?.production_line_collection?.find(
    ({
      production_line_id: id,
      machine_collection: machines,
    }: IProductionLine) =>
      machines?.find(
        ({ machine_id: id }: MachineCollection) => id === machineId,
      ),
  )?.production_line_id

  const { departmentName, isDeptFetching } = useGetDepartmentInfoQuery(
    departments?.department_id ?? "",

    {
      skip: !departments?.department_id,
      selectFromResult: ({ data, isFetching }) => ({
        departmentName: data?.department_name,
        isDeptFetching: isFetching,
      }),
    },
  )
  const { productionLineName, isProdLineFetching } =
    useGetProductionLineInfoQuery(
      prodId ?? "",

      {
        skip: !prodId,
        selectFromResult: ({ data, isFetching }) => ({
          productionLineName: data?.production_line_name,
          isProdLineFetching: isFetching,
        }),
      },
    )

  const dispatch = useDispatch()

  const closeDrawer = () => {
    dispatch(setIsDrawerDetailsOpen(false))
  }
  const isTextLoading = isDeptFetching || isProdLineFetching

  return (
    <Box sx={styles.root}>
      <Box sx={styles.topContainer}>
        <Box
          sx={{
            color: "primary.main",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => navigate("/facilities/machineDetailScreen")}
        >
          <AppTypography variant="h2" textTransform="capitalize">
            {replaceUnderscores(machineName)}
          </AppTypography>
        </Box>

        <IconButton
          onClick={closeDrawer}
          sx={{ color: "text.secondary" }}
          aria-label="close drawer"
        >
          <Close />
        </IconButton>
      </Box>
      {!isTextLoading && departmentName && productionLineName ? (
        <Box sx={styles.machineInfoContainer}>
          <AppTypography
            variant="subtitle1"
            sx={styles.text}
            capitalizeFirstLetter
          >
            {productionLineName ?? null}
          </AppTypography>
          <Box sx={styles.circleDivider} />
          <AppTypography
            variant="subtitle1"
            sx={styles.text}
            capitalizeFirstLetter
          >
            {departmentName ?? null}
          </AppTypography>
        </Box>
      ) : (
        <Skeleton width={100} />
      )}
    </Box>
  )
}

export default HeaderDetailView
