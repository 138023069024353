import React, { useMemo, useState } from "react"

import {
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js"
import { Line } from "react-chartjs-2"
import { RootState } from "store/store"

import { useAppGetMachineStateQuery } from "core/hooks/apiHooks"
import { useSelector } from "react-redux"

import { Box, useTheme } from "@mui/material"

import { calculateDaysFromMinutes, transformHealthHistoryData } from "./helpers"
import GraphBaseHeader from "./subcomponents/GraphBaseHeader"
import { styles } from "./styles"
import AppGraphWrapper from "core/components/AppCard/AppGraphWrapper"
import { useTranslation } from "react-i18next"
import StatusIcon from "core/components/StatusIcon"
import { getMaxTicksForGraphs, getTimeFormat, getTooltip } from "core/helpers"
import { get } from "http"
import { format } from "date-fns"

ChartJS.register(
  CategoryScale,
  Filler,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
)

type MachineHealthChartProps = {
  heightFactor?: number
  externalData?: any
  graphsInCurrentRow?: number
}
const MachineHealthChart = (props: MachineHealthChartProps) => {
  const chartRef = React.useRef(null)

  const CHART_LINE_WIDTH = 10

  const { heightFactor = 1 } = props
  const { externalData } = props
  const { graphsInCurrentRow = 1 } = props

  const { machineSelected } = useSelector((state: RootState) => state.machines)

  const { healthGraphSelectedRange } = useSelector(
    (state: RootState) => state.global,
  )

  const isDynamic = !!props?.heightFactor

  const {
    healthData = [],
    isFetching,
    isError,
    refetch,
  } = externalData ??
  useAppGetMachineStateQuery(
    {
      machine_id: machineSelected?.machine_id || "",
      type: "state",
      start_time: healthGraphSelectedRange.fromEpoch,
      end_time: healthGraphSelectedRange.toEpoch,
    },
    {
      skip: !machineSelected?.machine_id,
    },
  )

  const transformedData = transformHealthHistoryData(healthData)

  const theme = useTheme()
  const { t } = useTranslation()

  const options: ChartOptions<"line"> = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: getTooltip(transformedData.epoch) as any,
    },
    animation: false,
    responsive: true,
    maintainAspectRatio: false,

    elements: {
      point: {
        radius: 0,
      },
    },
    layout: {
      padding: {
        top: CHART_LINE_WIDTH / 2,
      },
    },

    scales: {
      y: {
        max: 3,
        ticks: {
          display: false,
          stepSize: 1,
          count: 4,
        },
        beginAtZero: true,
      },
      x: {
        ticks: {
          font: {
            size: 10,
          },
          color: theme.palette.text.secondary,
          maxTicksLimit: getMaxTicksForGraphs(graphsInCurrentRow),
          maxRotation: 0,
        },
      },
    },

    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
  }

  const data = useMemo(
    () => ({
      labels: transformedData.labels,
      datasets: [
        {
          label: "normal",
          data: transformedData.normal,
          borderWidth: CHART_LINE_WIDTH,
          borderColor: theme.palette.success.light50,
        },
        {
          label: "warning",
          data: transformedData.warning,
          fill: false,
          borderWidth: CHART_LINE_WIDTH,
          borderColor: theme.palette.warning.light50,
        },
        {
          label: "critical",
          clip: 10,
          data: transformedData.critical,
          borderWidth: CHART_LINE_WIDTH,
          fill: false,
          borderColor: theme.palette.error.light50,
        },
        {
          label: "idle",
          data: transformedData.idle,
          clip: 10,
          fill: false,
          borderWidth: CHART_LINE_WIDTH,
          borderColor: theme.palette.grey[300],
        },
      ],
    }),
    [transformedData],
  )

  const getTotalDaysPerType = (_data: any) => ({
    normal: calculateDaysFromMinutes(
      _data?.datasets[0].data.filter((el: any) => el !== null).length,
    ),
    warning: calculateDaysFromMinutes(
      _data?.datasets[1].data.filter((el: any) => el !== null).length,
    ),
    critical: calculateDaysFromMinutes(
      _data?.datasets[2].data.filter((el: any) => el !== null).length,
    ),
    idle: calculateDaysFromMinutes(
      _data?.datasets[3].data.filter((el: any) => el !== null).length,
    ),
  })

  return (
    <AppGraphWrapper
      isLoading={isFetching}
      hasError={isError}
      onRetry={refetch}
      graphContainerStyle={{ m: 0, p: 0, height: "auto" }}
      baseCardStyle={{
        boxShadow: isDynamic ? 0 : 1,
        m: 0,
        px: 1,
        pt: 1,
        height: 312 * heightFactor,
      }}
      subheader={
        <GraphBaseHeader
          hidePicker={isDynamic}
          daysPerType={getTotalDaysPerType(data)}
        />
      }
      restHeaderProps={{
        headerLabel: t("machine.health.chart.text"),
      }}
    >
      <Box
        sx={{
          ...styles.iconsGraphContainer,
          height: styles.iconsGraphContainer.height * heightFactor,
        }}
      >
        <Box
          sx={{
            ...styles.iconsContainer,
            height: styles.iconsContainer.height * heightFactor,
          }}
        >
          <StatusIcon state="critical" />
          <StatusIcon state="warning" />
          <StatusIcon state="normal" />
          <StatusIcon state="idle" />
        </Box>
        <Box sx={styles.graphContainer}>
          <Line role="figure" ref={chartRef} options={options} data={data} />
        </Box>
      </Box>
    </AppGraphWrapper>
  )
}

export default MachineHealthChart
