import { Box, Skeleton } from "@mui/material"
import AppTypography from "core/components/AppTypography"
import { t } from "i18next"
import React from "react"

import { useGetTempQuery } from "store/Assets/assetsSlice"
import { styles } from "./styles"

interface ICurrentTemperature {
  assetId?: string | null
}

const CurrentTemperature = ({ assetId = null }: ICurrentTemperature) => {
  const { latestTempValue, isError, isFetching } = useGetTempQuery(
    { assetId },
    {
      selectFromResult: ({ data, isError, isFetching }) => ({
        latestTempValue: data?.values[0]?.value.toFixed(0),
        isError,
        isFetching,
      }),
      skip: !assetId,
    },
  )

  return (
    <Box sx={styles.boxStyles}>
      {isFetching ? (
        <Skeleton variant="text" width={100} height={20} />
      ) : (
        <>
          <AppTypography sx={{ fontSize: "0.75rem" }} capitalizeFirstLetter>
            {`${t("current.temperature.text")}:`}
          </AppTypography>

          <AppTypography
            sx={styles.tempText}
          >{`${latestTempValue}`}</AppTypography>

          <AppTypography sx={{ fontSize: "0.938rem" }}>°C</AppTypography>
        </>
      )}
    </Box>
  )
}

export default CurrentTemperature
