export const api = {
  getMachine: "*view/dashboard/site/*/machine/*/cards/machine",
  getOrganization: "*/organisation/*",
  getUIConfig: "*get_ui_config*",
  getMachinesOverview: "*/site/*/machines/overview",
  getSite: "site/*",
  getMachineBarDurationWeek: "*bar_duration*?groupBy=day*",
  getMachineBarDurationMonth: "*bar_duration*?groupBy=week*",
  getMachineBarDurationYear: "*bar_duration*?groupBy=month*",
  getMachineDurationInState: "*/site/*/machine/*/duration_in_state",
  getMachineStateNoParams: "*machine_state*",
  getMachineState: "*machine_state?start_time=*?end_time=*",
  getAssetStateNoParams: "*/site/*/machine/*/asset/*/asset_state",
  getAssetState:
    "*view/dashboard/site/*/machine/*/asset/*/asset_state?start_time=*?end_time=*",
  getAssetTemperatureNoParams: "*view/dashboard/site/*/machine/*/asset/*/temp",

  getDepartment: "*/department/:departmentId",

  getProductonLine: "*/production_line/:production_line",
  getAsset: "*asset/:assetId",
  getAssetAcceleration: "*/rms/:assetId?axis=*",
  getTsData: "*get_ts_data*",
}
