import { machinesApiSlice } from "store/Machines/machinesSlice"
import { RootState } from "store/rootReducer"

import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit"

const filtersAdapter = createEntityAdapter({
  selectId: (filter: any) => filter.organisationId,
})

export const initialState = filtersAdapter.getInitialState({
  totalDepartmentMachines: 0,
  selectedFilters: [] as any[],
  selectedDepartmentIndex: "",
  appliedFilters: [] as string[],
})

export const extractDepartments = (
  { siteinformation = [] }: any,
  state?: any,
  siteId?: string,
) => {
  if (!siteinformation) return []
  const id = siteId ?? siteinformation[0]?.siteId

  return siteinformation
    .find((site: any) => site.siteId === id)
    .departmentCollection.map(({ departmentId, departmentName }: any) => ({
      id: departmentId,
      name: departmentName,
    }))
}

export const findTotalMachinesPerDepartment = (
  deptId: string,
  prodLines: any[],
) =>
  prodLines.reduce(
    (acc: any, val: any) => {
      if (deptId === val.departmentId) {
        return {
          sum: acc.sum + val.data.length,
          id: val.departmentId,
        }
      }

      return acc
    },
    { sum: 0 },
  )

export const extractProductionLines = (
  { siteinformation = [] }: any,
  departments?: any[],
  siteId?: string,
) => {
  if (!siteinformation) return []
  const id = siteId ?? siteinformation[0]?.siteId

  const productionLines = siteinformation
    .find((site: any) => site.siteId === id)
    .departmentCollection?.map(
      ({ data, departmentId, productionLineCollection }: any) =>
        productionLineCollection?.map(
          ({
            machineCollection,
            productionLineId,
            productionLineName,
          }: any) => ({
            id: productionLineId,
            departmentId,
            name: productionLineName,
            data: machineCollection ?? [],
          }),
        ),
    )
    .reduce((accumulator: any, val: any) => [...accumulator, val])
  return productionLines
}

const findMachinesToShow = (
  productionLine: any[],
  departmentIndex: string,
  initialValue = undefined,
) =>
  productionLine.reduce((acc: any, prodLine: any) => {
    if (prodLine.departmentId === departmentIndex) {
      return [...prodLine.data]
    }

    return acc
  }, initialValue)

const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setSelectedFilters: (state, action) => {
      state.selectedFilters = action.payload
    },
    setSelectedDepartmentIndex: (state, action) => {
      const { selectedDepartmentIndex } = action.payload
      state.selectedDepartmentIndex = selectedDepartmentIndex
    },
    filterSelection: (state, action) => {
      const { organizationId, selectedFilter } = action.payload
      state.selectedFilters = state.entities[
        organizationId
      ].productionLines.find((el: any) => el.id === selectedFilter).data
    },
    setAppliedFilter: (state, action) => {
      const appliedFilterIndex = state.appliedFilters.indexOf(action.payload)
      if (appliedFilterIndex === -1)
        state.appliedFilters = [...state.appliedFilters, action.payload]
      else {
        state.appliedFilters = state.appliedFilters.filter(
          (el) => el !== action.payload,
        )
      }
    },

    addFilter: filtersAdapter.addOne,
    clearAllFilters: (state, _action) => {
      state.selectedFilters = []
      state.selectedDepartmentIndex = ""
    },
  },
  extraReducers: (builder) => {
    // builder.addMatcher(
    //   (action: any) => action.type === "organizations/addOrganization",
    //   (state, action) => {
    //     const { payload } = action

    //     const depts = extractDepartments(payload, state)
    //     const prodLines = extractProductionLines(payload)
    //     const machines = depts.map((dept: any) =>
    //       findTotalMachinesPerDepartment(dept.id, prodLines),
    //     )
    //     const departmentsWithTotalMachines = depts.map(
    //       (dept: any, index: number) => {
    //         if (dept.id === machines[index].id) {
    //           return { ...dept, totalMachines: machines[index].sum }
    //         }
    //       },
    //     )

    //     filtersAdapter.addOne(state, {
    //       organisationId: payload.organisationId,
    //       departments: departmentsWithTotalMachines,
    //       productionLines: prodLines,
    //     })
    //   },
    // ),
    builder.addMatcher(
      (action: any) => action.type === "filters/setSelectedDepartmentIndex",
      (state, action) => {
        const { selectedDepartmentIndex, organisationId } = action.payload
        const stateObj = state.entities[organisationId]

        if (stateObj.productionLines.length === 1) {
          state.selectedFilters = findMachinesToShow(
            stateObj.productionLines,
            selectedDepartmentIndex,
            /**
             * If there is only one element in the array
             * apply the callback function to the first element
             *  */
            stateObj.productionLines[0],
          )
        } else if (stateObj.productionLines.length > 1) {
          state.selectedFilters = findMachinesToShow(
            stateObj.productionLines,
            selectedDepartmentIndex,
          )
        } else {
          state.selectedFilters = []
        }
      },
    )
  },
})

export const departmentsSelector = createSelector(
  [(state: RootState) => state.filters, (state, orgId: string) => orgId],
  (state, orgId) => {
    const organizationInfo = state?.entities[orgId]?.departments

    return organizationInfo
  },
)

export const productionLinesSelector = createSelector(
  [(state: RootState) => state.filters, (state, orgId: string) => orgId],
  (state, orgId) => {
    const organizationInfo = state.entities[orgId]?.productionLines
    const selectedDept = state.selectedDepartmentIndex
    const machinesToShow = organizationInfo?.filter((prodLine: any) => {
      if (prodLine.departmentId === selectedDept)
        return { prodLine, totalNumber: prodLine.length }
    })
    return machinesToShow
  },
)

export const getProductionLines = createSelector(
  [(state: RootState) => state.filters, (state, orgId: string) => orgId],
  (state, orgId) => state.entities[orgId].productionLines,
)

export const {
  addFilter,
  filterSelection,
  clearAllFilters,
  setSelectedDepartmentIndex,
  setAppliedFilter,
  setSelectedFilters,
} = filtersSlice.actions
export const { selectById: selectFilterById } = filtersAdapter.getSelectors(
  (state: RootState) => state.filters,
)

export default filtersSlice.reducer
