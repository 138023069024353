import React from "react"

import { Box } from "@mui/material"

import MachinesDatatableCard from "./subcomponents/MachinesDatatableCard"
import OverviewScreenHeader from "./subcomponents/OverviewScreenHeader"
import { styles } from "./styles"
import { Outlet, useMatch } from "react-router-dom"

interface Props {}

const Facilities = (props: Props) => {
  const isFacilities = useMatch("/facilities")

  return (
    <Box sx={styles.root}>
      {isFacilities ? (
        <>
          <OverviewScreenHeader />
          <MachinesDatatableCard />
        </>
      ) : (
        <Outlet />
      )}
    </Box>
  )
}
export default Facilities
