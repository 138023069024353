import {
  getIsTheUrlEUProject,
  getStatusColor,
  replaceUnderscores,
} from "core/helpers"
import {
  setSelectedMachine,
  useGetMachineCardQuery,
  useGetMachineCardStatusInfoQuery,
} from "store/Machines/machinesSlice"

import AppTypography from "core/components/AppTypography"
import { Box } from "@mui/material"
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined"
import React from "react"
import { styles } from "./styles"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Asset } from "models/interfaces/machine"
import { Machine } from "models/organization"
import {
  getDynamicScreens,
  getDynamicUrls,
} from "core/components/AppDrawerNavigation/constants/categoryTitles"
import { store } from "store/store"

export type IMachineProps = {
  machine: {
    machine_id: string
    asset_collection: Asset[]
  }
}

const MachineStatus = ({ machine }: { machine: Machine }) => {
  const { machine_id: machineId = "", asset_collection: assets = [] } =
    machine || {}

  const { t } = useTranslation()

  const { machineName = "" } = useGetMachineCardQuery(
    { machineId },
    {
      selectFromResult: ({ data }) => ({
        machineName: data?.machine_name,
      }),
    },
  )

  const dynamicUrls = getDynamicUrls()

  const getAssetsLabel = (numberOfAssets: number) =>
    numberOfAssets === 1
      ? `1 ${t("asset.text")}`
      : `${numberOfAssets} ${t("assets.text")}`
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onClick = () => {
    dispatch(setSelectedMachine(machine))
    getIsTheUrlEUProject()
      ? navigate(`/${dynamicUrls[0]}`)
      : navigate("/facilities/machineDetailScreen")
  }

  const { data } = useGetMachineCardStatusInfoQuery(
    machineId,

    { skip: !machineId },
  )
  const { state: machineStatus = "" } = data || {}

  return (
    <Box onClick={onClick} sx={styles.container}>
      <Box
        sx={(theme) => ({
          ...styles.statusBar,
          backgroundColor: getStatusColor(machineStatus, theme).main,
        })}
      />
      <AppTypography capitalizeFirstLetter variant="h6" sx={styles.title}>
        {replaceUnderscores(machineName)}
      </AppTypography>
      <AppTypography capitalizeFirstLetter variant="h5" sx={styles.serialText}>
        {`${t("machine.id.text")}: ${machineId}`}
      </AppTypography>

      <Box sx={styles.iconTextAssets}>
        <Inventory2OutlinedIcon sx={styles.icon} />
        <AppTypography variant="h5" sx={styles.text}>
          {getAssetsLabel(assets.length)}
        </AppTypography>
      </Box>
      {/* {machineStatus.toLowerCase() !== "normal" ? (
        <BookmarkIcon sx={styles.warningIcon} />
      ) : null} */}
    </Box>
  )
}

export default MachineStatus
