/* eslint-disable no-mixed-operators */
import { Box, useTheme } from "@mui/material"
import { getStatusColor } from "core/helpers"
import React from "react"
import ArcComponent from "./ArcComponent"
import CircleComponent from "./CircleComponent"

interface IAppCircularStatus {
  value: number
  color: string
  total: number
  label: string
}

const AppCircularStatus = ({
  value,
  color,
  total,
  label,
}: IAppCircularStatus) => {
  const VIEWBOX = 250
  const STROKE_WIDTH = (4 * VIEWBOX) / 100
  const TEXT_WIDTH = (3 * VIEWBOX) / 100
  const RADIUS = VIEWBOX / 2 - 10
  const CIRCLE_CENTER = { x: VIEWBOX / 2, y: VIEWBOX / 2 }
  const GAP_LENGTH = 0.03 * Math.PI
  const circumference = Number((RADIUS * 2 * Math.PI).toFixed(1))

  const getProgress = (input: number) => {
    const arcValue = (input * circumference) / total
    return Math.floor(Number(arcValue))
  }

  const theme = useTheme()
  const isArray = Array.isArray(value)

  const overviewItems: Array<{
    status: string
    color: string
    start: number
    end: number
  }> = []

  const calculateArcStart = (index: number, arr: Array<{ value: number }>) => {
    let sum = 0
    if (index === 0) return 0
    if (index === 1) return getProgress(arr[0].value) / RADIUS
    for (let i = 0; i < index; i++) {
      sum += getProgress(arr[i].value) / RADIUS
    }
    return sum
  }

  const calculateArcEnd = (index: number, arr: Array<{ value: number }>) => {
    let sum = 0
    const arrLength = arr.length - 1
    const sameOverviewStatus = arrLength === 0
    if (sameOverviewStatus) return getProgress(arr[0].value) / RADIUS
    if (index === 0) return getProgress(arr[0].value) / RADIUS - GAP_LENGTH
    if (index === arrLength) return 2 * Math.PI - GAP_LENGTH

    for (let i = arrLength; i > index; i--) {
      sum += getProgress(arr[i].value) / RADIUS
    }

    return 2 * Math.PI - sum - GAP_LENGTH
  }

  const generateArcs = (
    arr: Array<{ key: string; value: number }>,
    index: number,
  ) => {
    const overviewItem = arr[index]
    return {
      status: overviewItem.key,
      color: getStatusColor(overviewItem.key, theme).main,
      start: calculateArcStart(index, arr),
      end: calculateArcEnd(index, arr),
    }
  }

  if (isArray) {
    const arr = value
    arr.forEach((_el, index: number) => {
      const overviewItem = generateArcs(arr, index)
      overviewItems.push(overviewItem)
    })
  }

  const arcPathArray = isArray ? overviewItems : []
  const totalMachinesNum = (!!value && !isArray) || value === 0 ? value : total

  const startValue = !!value && !isArray ? getProgress(value) : 0
  const endValue = !!value && !isArray ? startValue / RADIUS : 0

  return (
    <Box sx={{ width: "4.688rem", margin: "0 auto" }}>
      <svg viewBox={`0 0 ${VIEWBOX} ${VIEWBOX}`}>
        {value === 0 ? (
          <ArcComponent
            rotate={-90}
            center={CIRCLE_CENTER}
            radius={RADIUS}
            strokeWidth={STROKE_WIDTH}
            start={0}
            end={endValue}
            color={color}
          />
        ) : !!value && !isArray ? (
          <ArcComponent
            rotate={-90}
            center={CIRCLE_CENTER}
            radius={RADIUS}
            strokeWidth={STROKE_WIDTH}
            start={0}
            end={endValue}
            color={color}
          />
        ) : (
          arcPathArray.map((el) => (
            <ArcComponent
              rotate={-90}
              key={el.color}
              center={CIRCLE_CENTER}
              radius={RADIUS}
              strokeWidth={STROKE_WIDTH}
              start={el.start}
              end={el.end}
              color={el.color}
            />
          ))
        )}

        <CircleComponent
          center={CIRCLE_CENTER}
          radius={RADIUS - STROKE_WIDTH * 2}
          strokeWidth={TEXT_WIDTH}
        />
        <text
          aria-label="statusSum"
          fontWeight={700}
          fill={getStatusColor(label, theme).main}
          fontSize={`${TEXT_WIDTH / 1.8}rem`}
          x="50%"
          y="50%"
          textAnchor="middle"
          dy=".4em"
        >
          {totalMachinesNum}
        </text>
      </svg>
    </Box>
  )
}

export default React.memo(AppCircularStatus)
