import "react-toastify/dist/ReactToastify.css"

import React from "react"

import AppCardLoader from "core/components/AppCardLoader"
import AppDrawerDetails from "core/components/AppDrawerDetails"
import ChartJsBarChart from "core/components/AppGraphViews/ChartJsBarChart"
import { selectSiteMachines } from "store/Machines/machinesSlice"
import { GRID_SPACING } from "core/constants/ui"
import MachineCard from "core/components/AppCard/MachineCard"
import { MachineCollection } from "models/organization"
import MachineDashboardCard from "core/components/AppMachineDashboardCard"
import MachinesOverview from "core/components/AppCard/MachinesOverview"
import NoDataFound from "core/components/NoDataFound"
import { RootState } from "store/store"
import { setOrganizationSelected } from "store/Organisation/organizationSlice"
import { setSelectedFilters } from "store/Filters/filtersSlice"
import { setSelectedSiteId } from "store/Sites/sitesSlice"

import { useAppGetOrganizationQuery } from "core/hooks/apiHooks"
import { batch, useDispatch, useSelector } from "react-redux"

import { Box, Grid } from "@mui/material"

import styles from "./styles"
import { navigateToLandingPage } from "core/helpers"
import { useNavigate } from "react-router-dom"

const Dashboard = () => {
  const dispatch = useDispatch()

  const selectOrganizationState = (state: RootState) => state.organizations
  const selectGlobalState = (state: RootState) => state.global
  const [disableRender, setDisableRender] = React.useState(true)

  const { organizationId } = useSelector(selectOrganizationState)
  const { isDrawerDetailsOpen, isUserLogged } = useSelector(selectGlobalState)

  const navigate = useNavigate()

  const {
    organizationData,
    isOrganizationFetching,
    isOrganizationError,
    refetch,
  } = useAppGetOrganizationQuery(organizationId, {
    skip: !isUserLogged && !organizationId,
  })

  const totalMachines: MachineCollection[] =
    useSelector((state: RootState) =>
      selectSiteMachines(state, { ...organizationData }),
    ) || []

  React.useEffect(() => {
    dispatch(setOrganizationSelected(organizationData))
    if (organizationData?.site_collection?.length === 1) {
      batch(() => {
        dispatch(setSelectedFilters(totalMachines))
        dispatch(setSelectedSiteId(organizationData.site_collection[0].site_id))
      })
    }
  }, [organizationData])

  React.useEffect(() => {
    navigateToLandingPage(navigate)
    setDisableRender(false)
  }, [])

  if (disableRender) return null

  if (organizationData?.site_collection?.length === 0)
    return <AppCardLoader isLoading={true} hasError={false} onRetry={refetch} />

  return (
    <Grid container spacing={GRID_SPACING}>
      <Grid item xs={12}>
        <Grid container spacing={GRID_SPACING} sx={styles().gridContainer}>
          <Grid item md={7} sm={12} lg={7}>
            <MachinesOverview />
          </Grid>
          <Grid item md={5} sm={12} lg={5}>
            <ChartJsBarChart />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={GRID_SPACING}>
          <Grid
            item
            sx={styles().gridTransition}
            xs={isDrawerDetailsOpen ? 8 : 12}
          >
            <AppCardLoader
              isLoading={isOrganizationFetching}
              hasError={isOrganizationError}
              onRetry={refetch}
            >
              <MachineDashboardCard>
                <Box sx={styles().cardsContainer}>
                  {!!totalMachines.length ? (
                    totalMachines.map((machine) => (
                      <MachineCard key={machine.machine_id} machine={machine} />
                    ))
                  ) : (
                    <NoDataFound />
                  )}
                </Box>
              </MachineDashboardCard>
            </AppCardLoader>
          </Grid>
          <Grid
            item
            xs={isDrawerDetailsOpen ? 4 : 0.1}
            sx={
              isDrawerDetailsOpen ? styles().drawerOpen : styles().drawerClose
            }
          >
            <AppDrawerDetails />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Dashboard
