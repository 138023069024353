import { format } from "date-fns";
import {
  getHealthBarGraphResolution, getTimeFormat,
  isOdd,
} from "core/helpers";

const HealthStatesConst = {
  0: "normal",
  1: "warning",
  2: "critical",
  3: "idle",
} as const

type HealthStateValues<T> = T[keyof T]
type HealthState = HealthStateValues<typeof HealthStatesConst>

type MachineHealthHistoryData = {
  labels: string[]
} & Record<HealthState, (null | number)[]>

export const calculateDaysFromMinutes = (hours: number) =>
  Math.floor(hours / getHealthBarGraphResolution() / 24)

export const transformHealthHistoryData = (
  rawData: { date: number; value: 0 | 1 | 2 | 3 }[],
) => {
  const labels: any[] = []
  const critical: any[] = []
  const warning: any[] = []
  const normal: any[] = []
  const idle: any[] = []
  const epoch: any[] = []

  const ONE_HOUR = 60 * 60 * 1000

  const fillArray = ({ type, time, _epoch }: any) => {
    for (let i = 0; i < getHealthBarGraphResolution(); i++) {
      switch (type) {
        case 3:
          critical.push(null)
          warning.push(null)
          normal.push(null)
          idle.push(0)
          break
        case 2:
          critical.push(3)
          warning.push(null)
          normal.push(null)
          idle.push(null)
          break
        case 1:
          warning.push(2)
          critical.push(null)
          normal.push(null)
          idle.push(null)

          break
        case 0:
          normal.push(1)
          critical.push(null)
          warning.push(null)
          idle.push(null)
          break
      }

      labels.push(time)
      epoch.push(_epoch)
    }
  }

  for (const el of rawData) {
    if (!el?.date) continue

    const _epoch = el.date * 1000

    const time = format(_epoch - ONE_HOUR, getTimeFormat())

    fillArray({ type: el.value, time, _epoch })
  }

  return {
    labels,
    critical,
    warning,
    normal,
    idle,
    epoch,
  }
}

export const transformData = (data: any[]) => {
  const temp: any[] = []

  const dummyData = transformHealthHistoryData(data)

  let i = 0
  dummyData.labels.forEach((el: any, index: any) => {
    if (isOdd(index)) {
      if (!temp[index - 1]) {
        temp[index - 1] = data[i]
      }

      temp[index] = data[i]
      temp[index + 1] = data[i]
      i++
    }
  })

  return temp
}

/**
 * Attempted to make a few optimizations to the transformHealthHistoryData function.
 * It does not take into account the GRAPH_RESOLUTION variable and saves quite a lot of loops.
 * Unfortunately, the granularity (GRAPH_RESOLUTION) needed for days and weeks is too high.
 * There is a solution to that by using pointRadius on the dataset, but visual is not quite right.
 * For periods more than a month this works fine. Using the pointRadius solution.
 */

export const transformHealthHistoryDataV2 = (
  rawData: { date: number; value: keyof typeof HealthStatesConst }[],
): MachineHealthHistoryData => {
  const labels = rawData.map(({ date }) => date * 1000)

  const healthStateKeys: HealthState[] = Object.values(HealthStatesConst)

  const healthStateArrays: (null | number)[][] = healthStateKeys.map((state) =>
    rawData.map((entry) =>
      HealthStatesConst[entry.value] === state ? entry.value + 1 : null,
    ),
  )

  const result = { labels } as any
  healthStateKeys.forEach((state, index) => {
    result[state] = healthStateArrays[index]
  })

  return result as MachineHealthHistoryData
}

export const calculatePointRadiusArray = (
  data: (number | null)[],
  width = 1,
): number[] =>
  data.map((el, i) => {
    const firstItem = i === 0

    if (
      firstItem &&
      el === null &&
      data[i + 1] !== null &&
      data[i + 2] === null
    )
      return width
    const prevValue = data[i - 1]
    const nextValue = data[i + 1]
    if (el !== null && prevValue === null && nextValue === null) return width
    return 0
  })
