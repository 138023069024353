// import { createLogger } from "redux-logger"

import { persistReducer, persistStore } from "redux-persist"
import { RootState as RootReducer } from "./rootReducer"
import { apiSlice } from "./queryApi"
import { configureStore } from "@reduxjs/toolkit"
import createSagaMiddleware from "redux-saga"
import rootReducer from "./rootReducer"

import { setupListeners } from "@reduxjs/toolkit/dist/query"
import storage from "redux-persist/lib/storage"

const sagaMiddleware = createSagaMiddleware()

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["api"],
}

const persistedReducer = persistReducer<RootReducer>(persistConfig, rootReducer)

const middlewares = [sagaMiddleware, apiSlice.middleware]

if (process.env.NODE_ENV === "development") {
  // const logger = createLogger({
  //   duration: true,
  //   diff: true,
  // })
  // middlewares.push(logger)
}

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(...middlewares),
})

setupListeners(store.dispatch)

export const createTestStore = (initialState: any) =>
  configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(...middlewares),
  })

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
