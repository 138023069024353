import { Box } from "@mui/system"

import DateRangePicker from "core/components/DateRangePicker"
import React from "react"
import { useSelector } from "react-redux"

import { setGlobalTimespan } from "store/rootSlice"
import { styles } from "./styles"

import { RootState } from "store/store"
import MachineSelect from "../MachineSelect"
import { isLevelUp } from "core/helpers"
import { levelUpMenuOptions } from "core/components/DateRangePicker/constants"

const Subheader = () => {
  const { globalTimespan } = useSelector((state: RootState) => state.global)

  const selectOptions = isLevelUp() ? levelUpMenuOptions : undefined
  return (
    <Box sx={styles.container}>
      <MachineSelect />
      <DateRangePicker
        selectedActionToDispatch={setGlobalTimespan}
        timespan={globalTimespan}
        selectOptions={selectOptions}
      />
    </Box>
  )
}

export default Subheader
