import * as yup from "yup"

const schema = yup.object().shape({
  username: yup.string().required("error.required").email("error.invalidMail"),
  password: yup
    .string()
    .required("error.required")
    .min(5, "error.passwordLength"),
})

export const validateForm = async (input: {
  username: string
  password: string
}) => schema.validate(input, { abortEarly: true })

export const validateField = async (input: Record<string, string>) => {
  const { field, value } = input
  //@ts-ignore
  const subSchema = schema.pick([`${field}`])
  return subSchema.validate({ [field]: value })
}

export const isFormValid = (form: { username: string; password: string }) =>
  schema.isValidSync(form)
