import { apiSlice } from "store/queryApi";
import { IGetSiteInfo } from "types/sitesApi";
import { IInitialStateSites } from "types/store";
import SitesAPI from "core/api/SitesApi";

import { createSlice } from "@reduxjs/toolkit";

export const initialState: IInitialStateSites = {
  siteSelected: null,
  sitesCollection: [],
  sitesCollectionDatatable: null,
  selectedSiteId: "",
}

const sitesSlice = createSlice({
  name: "sites",
  initialState,
  reducers: {
    setSelectedSite: (state, action) => {
      state.siteSelected = action.payload
    },
    setSelectedSiteId: (state, action) => {
      if (action.payload === state.selectedSiteId) return state
      state.selectedSiteId = action.payload
    },
    setSitesList: (state, action) => {
      state.sitesCollection = action.payload
    },
    setSitesCollectionDatatable: (state, action) => {
      state.sitesCollectionDatatable = action.payload
    },
  },
})

export const sitesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSiteInfo: builder.query<IGetSiteInfo, string>({
      queryFn: (arg, api, extraOptions, baseQuery) => {
        const siteUUID = arg
        return SitesAPI.getSiteInfo(siteUUID)
      },
      // one year
      keepUnusedDataFor: 31557600,
    }),
  }),
})

export const { useGetSiteInfoQuery, useLazyGetSiteInfoQuery } = sitesApiSlice

export const {
  setSelectedSite,
  setSelectedSiteId,
  setSitesCollectionDatatable,
  setSitesList,
} = sitesSlice.actions

export default sitesSlice.reducer
