import React from "react"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { RootState } from "store/store"
import { useDispatch, useSelector } from "react-redux"
import { MachineCollection } from "models/organization"
import {
  setSelectedMachine,
  selectSiteMachines,
} from "store/Machines/machinesSlice"
import { useAppGetOrganizationQuery } from "core/hooks/apiHooks"
const MachineSelect = () => {
  const selectOrganizationState = (state: RootState) => state.organizations
  const selectMachinesState = (state: RootState) =>
    state.machines.machineSelected

  const { organizationId } = useSelector(selectOrganizationState)
  const { machine_id: machineId } = useSelector(selectMachinesState)

  const dispatch = useDispatch()

  const { organizationData } = useAppGetOrganizationQuery(organizationId)

  const totalMachines: MachineCollection[] =
    useSelector((state: RootState) =>
      selectSiteMachines(state, { ...organizationData }),
    ) || []

  const handleChange = (event: SelectChangeEvent) => {
    dispatch(
      setSelectedMachine(
        totalMachines.find(
          ({ machine_id }) => machine_id === event.target.value,
        ),
      ),
    )
  }

  return (
    <FormControl variant="standard">
      <InputLabel id="machine-select-label">Select a Machine</InputLabel>
      <Select
        disableUnderline
        labelId="machine-select"
        value={machineId}
        label="Select a Machine"
        onChange={handleChange}
      >
        {totalMachines.map(({ machine_id }) => (
          <MenuItem key={machine_id} value={machine_id}>
            {machine_id}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default MachineSelect
