import React from "react"

import AppTypography from "core/components/AppTypography"
import BaseCard from "core/components/AppCard/BaseCard"
import ConditionStatusInformation from "core/components/AppCard/MachineCard/subcomponents/ConditionStatusInformation"
import { getDepartmentsSelector } from "store/Department/departmentSlice"

import ImgWithPlaceholder from "core/components/ImgWithPlaceholder"
import { ISite } from "models/interfaces/site"
import { RootState } from "store/store"
import { useGetOrganizationQuery } from "store/Organisation/organizationSlice"
import { useGetSiteInfoQuery } from "store/Sites/sitesSlice"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined"
import { Box, Tabs } from "@mui/material"
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined"
import PrecisionManufacturingOutlinedIcon from "@mui/icons-material/PrecisionManufacturingOutlined"

import SiteTab from "./subcomponents/SiteTab"
import { styles } from "./styles"
import { selectSiteMachines } from "store/Machines/machinesSlice"

const OverviewScreenHeader = () => {
  const { t } = useTranslation()

  const sitesState = (state: RootState) => state.sites
  const selectOrganizationState = (state: RootState) => state.organizations
  const { selectedSiteId } = useSelector(sitesState)
  const { organizationId } = useSelector(selectOrganizationState)

  const { siteCollection, isSuccess } = useGetOrganizationQuery(
    {
      uuid: organizationId,
    },
    {
      skip: !organizationId,
      selectFromResult: ({ data, isSuccess }) => ({
        siteCollection: data?.site_collection ?? [],
        isSuccess,
      }),
    },
  )

  const { imageUrl, siteName } = useGetSiteInfoQuery(selectedSiteId, {
    skip: !selectedSiteId && isSuccess,
    selectFromResult: ({ data }) => ({
      siteName: data?.site_name,
      imageUrl: data?.site_description,
    }),
  })

  const departments = useSelector((state: RootState) =>
    getDepartmentsSelector(state, selectedSiteId),
  )

  const totalMachineCount = useSelector((state: RootState) =>
    selectSiteMachines(state, { site_collection: siteCollection }),
  ).length

  const getMachinesLabel = (numberOfMachines: number) =>
    numberOfMachines === 1
      ? `1 ${t("machine.text")}`
      : `${numberOfMachines} ${t("machines.text")}`

  const getCollectionsLabel = (numberOfCollection: number) =>
    numberOfCollection === 1
      ? `1 ${t("department.text")}`
      : `${numberOfCollection} ${t("departments.text")}`

  return (
    <>
      <Box sx={styles.boxContainer}>
        <Tabs value={selectedSiteId} sx={styles.tablist}>
          {siteCollection.map(({ site_id: siteId }: ISite) => (
            <SiteTab value={siteId} id={siteId} key={siteId} />
          ))}
        </Tabs>
      </Box>
      <BaseCard sx={styles.baseCard}>
        <Box sx={styles.infoContainer}>
          <ImgWithPlaceholder
            imgSrc={imageUrl}
            placeholderText={t("add.site.img.text")}
          />
          <Box sx={styles.imgAndInfoContainer}>
            <AppTypography variant="h2" sx={styles.siteNameText}>
              {siteName}
            </AppTypography>
            <ConditionStatusInformation
              sx={{ mt: 3 }}
              info={
                <AppTypography
                  variant="subtitle1"
                  capitalizeFirstLetter
                  // sx={{ fontSize: "0.75rem" }}
                >
                  address
                </AppTypography>
              }
              icon={<LocationOnOutlinedIcon sx={{ width: "20px" }} />}
            />
            <ConditionStatusInformation
              sx={{ mt: 2 }}
              icon={<ApartmentOutlinedIcon sx={{ width: "18px" }} />}
              info={
                <AppTypography variant="subtitle1">
                  {getCollectionsLabel(departments.length)}
                </AppTypography>
              }
            />
            <ConditionStatusInformation
              sx={{ mt: 2 }}
              info={
                <AppTypography variant="subtitle1" textTransform="capitalize">
                  {getMachinesLabel(totalMachineCount)}
                </AppTypography>
              }
              icon={
                <PrecisionManufacturingOutlinedIcon sx={{ width: "18px" }} />
              }
            />
          </Box>
        </Box>
      </BaseCard>
    </>
  )
}

export default OverviewScreenHeader
