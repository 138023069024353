import React from "react"

import AppCircularStatus from "core/components/AppCircularStatus"
import AppTypography from "core/components/AppTypography"
import { useTranslation } from "react-i18next"

import { Box } from "@mui/material"

import ItemIndicator from "../ItemIndicator"
import { styles } from "./styles"

export interface MachineOverviewItemProps {
  onClick?: (val?: number | string) => void
  selected: boolean
  showPercentage?: boolean
  value: number
  label?: string
  total?: number
  color?: string
  key: string
}
const MachineOverviewItem = (props: MachineOverviewItemProps) => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClick = () => {},
    selected,
    showPercentage = false,
    label = "",
    value,
    total = 0,
    color = "#000",
  } = props

  const handleOnClick = () => onClick(label)
  const { t } = useTranslation()
  const isArrayOfValues = Array.isArray(value)
  const percentage =
    showPercentage && !isArrayOfValues
      ? (Number(value) / Number(total)) * 100
      : null

  return (
    <Box sx={styles().container} onClick={handleOnClick}>
      <Box sx={styles().svgContainer}>
        <AppCircularStatus
          color={color}
          label={label}
          value={value}
          total={total}
        />

        <AppTypography
          variant="h5"
          sx={styles().labelText}
          typographyProps={{ align: "center" }}
        >
          {t(`${label}.text`)}
        </AppTypography>
        <AppTypography
          sx={styles().text}
          typographyProps={{
            align: "center",
          }}
        >
          {!!showPercentage && !isArrayOfValues && percentage
            ? `${Math.trunc(percentage)}%`
            : null}
        </AppTypography>
      </Box>
      <ItemIndicator visible={false} />
    </Box>
  )
}

export default MachineOverviewItem
