import { apiSlice } from "store/queryApi"
import AssetsAPI from "core/api/AssetsApi"
import {
  IGenerateAssetOverallInformation,
  IGenerateConditionMonitoringCard,
  IPostGetTimeseriesParams,
  RMS,
  temperature,
} from "types/assetsApi"
import { IInitialStateAsset } from "types/store"
import { addHours, isValid, millisecondsToSeconds, subMinutes } from "date-fns"
import { RootState } from "store/store"

import { createSlice } from "@reduxjs/toolkit"

const selectInferenceMeasurement = (api: any, arg: any) => {
  const state = api.getState() as RootState
  const selectMeasurement =
    arg.select === "temperature"
      ? state.global.temperatureGraphSelectedRange
      : state.global.vibrationGraphSelectedRange

  const fromEpoch = isValid(selectMeasurement.fromEpoch)
    ? millisecondsToSeconds(
        subMinutes(new Date(), selectMeasurement.fromEpoch).valueOf(),
      )
    : null
  const toEpoch = isValid(selectMeasurement.fromEpoch)
    ? millisecondsToSeconds(new Date().valueOf())
    : null

  return { ...arg, fromEpoch, toEpoch }
}

const transformPredictData = (response: any) => {
  const emptyResponse = { ...response, data: [] }

  // Edge case: Handle null/undefined or non-object responses
  if (typeof response !== "object" || response === null) {
    return emptyResponse
  }

  const { data } = response

  // Edge case: Handle null/undefined or non-array data
  if (!Array.isArray(data)) {
    return emptyResponse
  }

  // Edge case: Handle empty data array
  if (data.length === 0) {
    return response // Return the input response as-is
  }

  // Refactored transformation to handle invalid data formats
  const transformedResponse = data.map((item) => {
    if (!Array.isArray(item) || item.length !== 2) {
      return emptyResponse
    }

    const [date, value] = item

    // Edge case: Handle invalid date or value types
    if (typeof date !== "string" || typeof value !== "number") {
      return emptyResponse
    }

    return { date, value }
  })

  return { ...response, data: transformedResponse }
}

const initialState: IInitialStateAsset = {
  assetSelected: { asset_id: "", assetId: "", assetName: " ", machineId: "" },
  assetAxisSelected: "x",
  assetTypeVelocity: "acceleration",
  lastArgs: {},
}

export const assetsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    generateAssetOverallInfo: builder.query<
      any,
      IGenerateAssetOverallInformation
    >({
      query: (asset: IGenerateAssetOverallInformation) => ({
        api: AssetsAPI.generateAssetOveralInfrormation(asset),
      }),
    }),
    inferenceGraphData: builder.query<any, any>({
      queryFn: (arg, api) => {
        const newArgs = selectInferenceMeasurement(api, arg)
        return AssetsAPI.getBeatBoxRMSByBeatBoxIDFormedTimeFrame(newArgs)
      },
    }),
    conditionMonitoring: builder.query<any, IGenerateConditionMonitoringCard>({
      query: (cm: IGenerateConditionMonitoringCard) => ({
        api: AssetsAPI.generateConditionMonitoringCard(cm),
      }),
      transformResponse: (response: any) => {
        const {
          acceleration_x: acc_x,
          acceleration_y: acc_y,
          acceleration_z: acc_z,
          temperature,
        } = response

        return { acc_x, acc_y, acc_z, temperature }
      },
    }),
    assetInfo: builder.query<any, any>({
      query: (assetId: string) => ({
        api: AssetsAPI.getAssetInfo(assetId),
      }),
    }),
    getRMS: builder.query<any, any>({
      query: ({ assetId, axis, fromEpoch }: RMS) => ({
        api: AssetsAPI.getRMS({
          assetId,
          axis,
          fromEpoch,
          toEpoch: new Date().valueOf(),
        }),
      }),
    }),
    getTemp: builder.query<any, any>({
      query: ({ assetId, start_time }: temperature) => ({
        api: AssetsAPI.getTemp({
          assetId,
          start_time,
          end_time: new Date().valueOf(),
        }),
      }),
    }),
    postGetTsData: builder.query<any, any>({
      query: (body: IPostGetTimeseriesParams) => ({
        api: AssetsAPI.postGetTsData(
          body,
          //   {
          //   ...body,
          //   end_time: new Date().toISOString(),
          // }
        ),
      }),

      transformResponse: (response: any) => {
        const emptyResponse = { ...response, data: [] }

        // Edge case: Handle null/undefined or non-object responses
        if (typeof response !== "object" || response === null) {
          return emptyResponse
        }

        const { data } = response

        // Edge case: Handle null/undefined or non-array data
        if (!Array.isArray(data)) {
          return emptyResponse
        }

        // Edge case: Handle empty data array
        if (data.length === 0) {
          return response // Return the input response as-is
        }

        // Refactored transformation to handle invalid data formats
        const transformedResponse = data.map((item) => {
          if (!Array.isArray(item) || item.length !== 2) {
            return emptyResponse
          }

          const [date, value] = item

          // Edge case: Handle invalid date or value types
          if (typeof date !== "string" || typeof value !== "number") {
            return emptyResponse
          }

          return { date, value }
        })

        return { ...response, data: transformedResponse }
      },
    }),
    postGetPredictData: builder.query<any, any>({
      async queryFn(arg) {
        const END_DATE = new Date()
        const { originalDataBody, predictionRequestBody } = arg
        const result = await AssetsAPI.postGetTsData({
          ...originalDataBody,
          end_time: END_DATE.toISOString(),
        })

        if (result.error) return { error: result.error }
        const END_TIME = addHours(END_DATE, 1).toISOString()
        const predictionResult = await AssetsAPI.postGetTsData({
          ...predictionRequestBody,
          end_time: END_TIME,
        })

        const { data: originalData } = transformPredictData(result.data)
        const { data: predictionData } = transformPredictData(
          predictionResult.data,
        )

        return {
          data: {
            originalData,
            predictionData,
          },
        }
      },
    }),
    getVelocity: builder.query<any, any>({
      query: ({ assetId, axis, fromEpoch }: RMS) => ({
        api: AssetsAPI.getVelocity({
          assetId,
          axis,
          fromEpoch,
          toEpoch: new Date().valueOf(),
        }),
      }),
    }),
  }),
})

const assetsSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {
    setAssetAxisSelected: (state, action) => {
      state.assetAxisSelected = action.payload
    },
    setAssetTypeVelocity: (state, action) => {
      state.assetTypeVelocity = action.payload
    },

    setSelectedAsset: (state, action) => {
      state.assetSelected = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      assetsApiSlice.endpoints.inferenceGraphData.matchFulfilled,
      (state, action) => {
        state.lastArgs = action.meta.arg.originalArgs
      },
    )
  },
})

export const {
  useConditionMonitoringQuery,
  useLazyInferenceGraphDataQuery,
  useInferenceGraphDataQuery,
  useAssetInfoQuery,
  useGetRMSQuery,
  useGetTempQuery,
  usePostGetTsDataQuery,
  useGetVelocityQuery,
  usePostGetPredictDataQuery,
} = assetsApiSlice

export const { setAssetAxisSelected, setSelectedAsset, setAssetTypeVelocity } =
  assetsSlice.actions

export default assetsSlice.reducer
