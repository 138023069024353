import React, { useRef } from "react"

import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  LinearScale,
  Tooltip,
} from "chart.js"
import { Bar } from "react-chartjs-2"
import { useTheme } from "@mui/material"

import { transformDataForGraph } from "./helpers"

ChartJS.register(CategoryScale, LinearScale, BarElement, BarController, Tooltip)

const BarChart = ({ data }: any) => {
  const chartRef = useRef<ChartJS<"bar">>(null)

  const theme = useTheme()

  const options: ChartOptions<"bar"> = {
    animation: false,
    events: [],

    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
    },

    scales: {
      y: {
        grid: {
          display: false,
        },
        display: false,
        stacked: true,
      },
      x: {
        ticks: {
          font: {
            size: 10,
          },

          color: theme.palette.text.secondary,
        },
        grid: {
          drawTicks: false,
          display: false,
        },
        stacked: true,
      },
    },

    responsive: true,
    maintainAspectRatio: false,
  }

  return (
    <Bar
      ref={chartRef}
      options={options}
      data={transformDataForGraph(data, theme)}
    />
  )
}

export default BarChart
