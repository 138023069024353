import { useTheme } from "@mui/material"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import { RootState } from "store/rootReducer"
import { AppDispatch } from "store/store"

export const useIsDarkTheme = () => {
  const theme = useTheme()
  const isDarkTheme = theme.palette.mode === "dark"
  return { isDarkTheme, theme }
}

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
