import { Box, ButtonBase, Skeleton, TabsProps } from "@mui/material"

import React from "react"
import { styles } from "./styles"
import { useAssetInfoQuery } from "store/Assets/assetsSlice"

type AssetTabProps = {
  assetId: string
  index: number
  showDivider: boolean
  onChange?: (event: React.SyntheticEvent | null, value: number) => void
  selected?: boolean
  value?: number
} & TabsProps

const AssetTab = ({ assetId, index, showDivider, ...rest }: AssetTabProps) => {
  const {
    onChange = () => console.log("AssetTab onChange"),
    selected = false,
    value = 0,
  } = rest
  const props = { value: index }
  const handleClick = () => {
    onChange(null, value)
  }
  const { assetName = "", isFetching } = useAssetInfoQuery(assetId, {
    selectFromResult: ({ data, isFetching }) => ({
      assetName: data?.asset_name,
      isFetching,
    }),
  })

  /** i think this could be better implemented based on this answer
   * mui v5 (2022)
   * https://stackoverflow.com/questions/35581478/how-to-add-ripple-effect-when-clicking-card-in-mui
   */

  return isFetching ? (
    <Skeleton variant="text" width={100} sx={{ fontSize: "1rem" }} />
  ) : (
    <Box
      sx={
        selected
          ? { ...styles.container, ...styles.customTabSelected }
          : styles.container
      }
    >
      <Box component={ButtonBase} onClick={handleClick} {...props}>
        {assetName}
      </Box>

      {showDivider && <Box sx={styles.statusIndicator} />}
    </Box>
  )
}
export default AssetTab
