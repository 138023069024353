import { apiSlice } from "store/queryApi"
import DepartmentsAPI, { IDepartmentInfo } from "core/api/DepartmentsApi"
import { IInitialStateDepartment } from "types/store"
import { ISite } from "models/interfaces/site"
import { organizationApiSlice } from "store/Organisation/organizationSlice"
import { RootState } from "store/store"

import { Action, createSlice } from "@reduxjs/toolkit"
import { setSelectedSite } from "store/Sites/sitesSlice"

const initialState: IInitialStateDepartment = {
  departmentId: null,
  departmentSelected: null,
  departmentsCollection: null,
  departmentsCollectionDatatable: null,
}

const departmentSlice = createSlice({
  name: "departments",
  initialState,
  reducers: {
    setDepartmentId: (state, action) => {
      state.departmentId = action.payload
    },
    setSelectedDepartmentId: (state, action) => {
      state.departmentId = action.payload
    },

    setSelectedDepartment: (state, action) => {
      state.departmentSelected = action.payload
    },
    setDepartmentsCollectionDatatable: (state, action) => {
      state.departmentsCollectionDatatable = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action: Action) => action.type === setSelectedSite,
      (state, action) => {
        const { payload } = action
        const firstDepartmentId = payload?.departmentCollection[0]?.departmentId
        state.departmentId = firstDepartmentId
      },
    )
  },
})

export const departmentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDepartmentInfo: builder.query<IDepartmentInfo, string>({
      query: (deptID) => ({
        api: DepartmentsAPI.getDepartmentInfo(deptID),
      }),
    }),
  }),
})

export const getDepartmentsSelector = (
  state: RootState,
  siteId: string | null,
) => {
  const { organizationId = null } = state.organizations || {}

  if (!organizationId) return []
  if (!siteId) return []

  const dataOrganizationId =
    organizationApiSlice.endpoints.getOrganization.select({
      uuid: organizationId,
    })(state).data

  if (dataOrganizationId === undefined) return []

  const site: ISite | undefined =
    organizationApiSlice.endpoints.getOrganization
      .select({ uuid: organizationId })(state)
      ?.data?.site_collection?.find((site) => site.site_id === siteId) ??
    undefined

  if (!site) return []

  return site.department_collection
}

export const { useGetDepartmentInfoQuery } = departmentApiSlice
export const {
  setDepartmentId,
  setSelectedDepartment,
  setSelectedDepartmentId,
  setDepartmentsCollectionDatatable,
} = departmentSlice.actions

export default departmentSlice.reducer
