import { DYNAMIC_UI_URL_PATH } from "core/constants/ui"
import { setDynamicUI } from "store/UI/UISlice"
import { store } from "store/store"
import UIAPI from "core/api/UI"

import { getIsTheUrlEUProject, isIncludedInUrl, raise } from "./"

export const getDynamicUIFileName = (): string => {
  const { user } = store.getState().userInfo
  return `org_id_${user["custom:client-organisation"]}.yaml`
}

export const isDynamicUIEnabled = () =>
  isIncludedInUrl([DYNAMIC_UI_URL_PATH]) || getIsTheUrlEUProject()

export const isMockingEnabled = () => isIncludedInUrl(["mocked-backend"])

export const enableDynamicUI = async ({ organizationId }: any) => {
  !organizationId && raise("organization id not required")

  if (isDynamicUIEnabled()) {
    const configUIRequestResponse = await UIAPI.getUIConfig({
      yaml_file_name: getDynamicUIFileName(),
    })

    store.dispatch(setDynamicUI(configUIRequestResponse.data?.configUI))
  }
}
