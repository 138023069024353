import React, { BaseSyntheticEvent, useState } from "react"

import { enableMocks, getIsTheUrlEUProject, isCalpak } from "core/helpers"

import { Box } from "@mui/material"

import logoCoreBeat from "../../../../assets/logos/cb_logo_orange.png"
import logoCoreIC from "../../../../assets/logos/coreic.png"
import { styles } from "./styles"
import { useDispatch } from "react-redux"
import { setBackendEnvironment } from "store/rootSlice"
import axios from "axios"
import { signIn } from "core/auth/auth"
import { ENVS } from "core/config"

let timeout: NodeJS.Timeout | undefined

export const NUMBER_OF_CLICKS_REQUIRED = 2
export const CODE_FOR_LOCALHOST = "0000"
export const CODE_FOR_DEV = "1000"
export const CODE_FOR_CALPAK = "1002"
export const CODE_FOR_PROD = "2000"
export const CODE_FOR_DUMMY_DATA_TEST = "3000"
export const CODE_FOR_MOCKED_BACKEND_LOGIN = "5000"
export const CODE_FOR_MOCKED_BACKEND_LOGIN_WITH_DYNAMIC_UI = "5001"

interface ILogo {
  formState: {
    username: string
    password: string
  }
}

const Logo = ({ formState }: ILogo) => {
  const logo = logoCoreBeat
  const [count, setCount] = useState(NUMBER_OF_CLICKS_REQUIRED)
  const [dialogVisible, setDialogVisible] = useState(false)
  const [userCode, setUserCode] = useState("")
  const dispatch = useDispatch()

  const onClick = () => {
    if (isCalpak()) setDialogVisible(false)
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      setCount(NUMBER_OF_CLICKS_REQUIRED)
    }, 500)

    const newCount = count - 1
    setCount(newCount)

    if (count === 0) {
      setDialogVisible(true)
    }
  }

  const hideAlertDialog = () => {
    setDialogVisible(false)
  }

  const setGlobalDebugCodeLocalStorage = async (codeType: string) =>
    await await localStorage.setItem("globalDebugCode", codeType)
  const setBackendEnvironmentLocalStorage = async (codeType: string) =>
    await await localStorage.setItem("backendEnvironment", codeType)

  const handleCase = async (env: string, type: string, codeType: string) => {
    axios.defaults.baseURL = env
    dispatch(setBackendEnvironment(type))
    setBackendEnvironmentLocalStorage(type)
    dispatch(setGlobalDebugCode(Number(codeType)))
    setGlobalDebugCodeLocalStorage(codeType)

    if (userCode === CODE_FOR_MOCKED_BACKEND_LOGIN) {
      await enableMocks()
      await signIn(formState)
    }
  }

  const lookupCode = () => {
    switch (userCode) {
      case CODE_FOR_DEV:
        handleCase(ENVS.DEV, "DEV", CODE_FOR_DEV)
        break

      case CODE_FOR_DUMMY_DATA_TEST:
        handleCase(ENVS.PROD, "PROD", CODE_FOR_DUMMY_DATA_TEST)
        break

      case CODE_FOR_LOCALHOST:
        handleCase(ENVS.LOCAL, "LOCAL", CODE_FOR_LOCALHOST)
        break

      case CODE_FOR_PROD:
        handleCase(ENVS.PROD, "PROD", CODE_FOR_PROD)
        break

      case CODE_FOR_CALPAK:
        handleCase(ENVS.PROD, "PROD", CODE_FOR_CALPAK)
        break

      case CODE_FOR_MOCKED_BACKEND_LOGIN:
        handleCase(ENVS.PROD, "PROD", CODE_FOR_MOCKED_BACKEND_LOGIN)
        break

      case CODE_FOR_MOCKED_BACKEND_LOGIN_WITH_DYNAMIC_UI:
        handleCase(
          ENVS.PROD,
          "PROD",
          CODE_FOR_MOCKED_BACKEND_LOGIN_WITH_DYNAMIC_UI,
        )
        break

      default:
        break
    }

    setDialogVisible(false)
  }

  const onChangeText = (e: BaseSyntheticEvent) => {
    setUserCode(e.target.value)
  }

  return (
    <Box data-testid="logo" sx={styles.logoContainer}>
      <img src={logo} alt="logo" style={styles.logo} />
    </Box>
  )
}

export default Logo
function setGlobalDebugCode(arg0: number): any {
  throw new Error("Function not implemented.")
}
