import { rest } from "msw";

import { api } from "./api";
import { mockedResponses } from "./mockedResponses";

export const appHandlers = {
  MachineSuccessHandler: rest.get(api.getMachine, (req, res, ctx) =>
    res(ctx.status(200), ctx.json(mockedResponses.machine)),
  ),

  SiteSuccessHandler: rest.get(api.getSite, (req, res, ctx) =>
    res(ctx.status(200), ctx.json(mockedResponses.site)),
  ),

  MachineBarDurationWeekSuccessHandler: rest.get(
    api.getMachineBarDurationWeek,
    (req, res, ctx) =>
      res(ctx.status(200), ctx.json(mockedResponses.machineStateGraphDataWeek)),
  ),

  MachineBarDurationMonthSuccessHandler: rest.get(
    api.getMachineBarDurationMonth,
    (req, res, ctx) =>
      res(
        ctx.status(200),
        ctx.json(mockedResponses.machineStateGraphDataMonth),
      ),
  ),
  ConfigUIHandlerGet: rest.get(api.getUIConfig, (req, res, ctx) =>
    res(ctx.status(200), ctx.json(mockedResponses.get_ui_config)),
  ),

  ConfigUIHandlerPost: rest.post(api.getUIConfig, (req, res, ctx) =>
    res(ctx.status(200), ctx.json(mockedResponses.get_ui_config)),
  ),

  GetTimeSeries: rest.post(api.getTsData, (req, res, ctx) =>
    res(ctx.status(200), ctx.json(mockedResponses.assetTemperature)),
  ),

  MachineBarDurationYearSuccessHandler: rest.get(
    api.getMachineBarDurationYear,
    (req, res, ctx) =>
      res(ctx.status(200), ctx.json(mockedResponses.machineStateGraphDataYear)),
  ),

  MachineDurationInState: rest.get(
    api.getMachineDurationInState,
    (req, res, ctx) =>
      res(ctx.status(200), ctx.json(mockedResponses.machineStateDuration)),
  ),

  MachineState: rest.get(api.getMachineState, (req, res, ctx) =>
    res(ctx.status(200), ctx.json(mockedResponses.machineState)),
  ),

  MachineStateNoParams: rest.get(api.getMachineStateNoParams, (req, res, ctx) =>
    res(ctx.status(200), ctx.json(mockedResponses.machineStateNoParams)),
  ),

  AssetState: rest.get(api.getAssetState, (req, res, ctx) =>
    res(ctx.status(200), ctx.json(mockedResponses.assetState)),
  ),

  AssetStateNoParams: rest.get(api.getAssetStateNoParams, (req, res, ctx) =>
    res(ctx.status(200), ctx.json(mockedResponses.assetStateNoParams)),
  ),

  AssetTempNoParams: rest.get(
    api.getAssetTemperatureNoParams,
    (req, res, ctx) =>
      res(ctx.status(200), ctx.json(mockedResponses.assetTemperatureNoParams)),
  ),
  DepartmentSuccessHandler: rest.get(api.getDepartment, (req, res, ctx) =>
    res(ctx.status(200), ctx.json(mockedResponses.department)),
  ),

  AssetSuccessHandler: rest.get(api.getAsset, (req, res, ctx) =>
    res(ctx.status(200), ctx.json(mockedResponses.asset)),
  ),
  AssetAccelerationHandler: rest.get(
    api.getAssetAcceleration,
    (req, res, ctx) =>
      res(ctx.status(200), ctx.json(mockedResponses.assetAcceleration)),
  ),

  OrganizationSuccessHandler: rest.get(api.getOrganization, (req, res, ctx) =>
    res(ctx.status(200), ctx.json(mockedResponses.organization)),
  ),
  ProductionLineSuccessHandler: rest.get(
    api.getProductonLine,
    (req, res, ctx) =>
      res(ctx.status(200), ctx.json(mockedResponses.productionLine)),
  ),
  ProductionLineErrorHandler: rest.get(api.getProductonLine, (req, res, ctx) =>
    res(ctx.status(500), ctx.json({ message: "Internal Server Error" })),
  ),
}

export const handlers = [
  ...Object.keys(appHandlers).map((key) => appHandlers[key]),
]
