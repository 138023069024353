import {
  calculateUnit,
  clearLocalStorageWithExceptions,
  notificationType,
} from "core/helpers"
import { getLocalStorageScreenNames } from "core/navigation/AppRoutes"
import { IInitialStateRoot } from "types/store"
import { millisecondsToSeconds, subDays, subMinutes } from "date-fns"
import { TBackendEvironments } from "types/misc"

import { createSlice } from "@reduxjs/toolkit"

import { assetsApiSlice } from "./Assets/assetsSlice"
import {
  setHealthTimespan,
  setTemperatureTimespan,
  setVibrationTimespan,
} from "./Machines/machinesSlice"

const savedTheme = localStorage.getItem("themeMode") as "light" | "dark"

const getLandingPage = () => {
  let url = "login"

  if (window.location.href.includes("kalinoches")) {
    localStorage.clear()
    url = "kalinoches"
  }

  if (window.location.href.includes("login")) {
    localStorage.clear()
    url = "login"
  }

  return url
}

const initialState: IInitialStateRoot = {
  language: "en",
  isDrawerOpen: false,
  isDrawerDetailsOpen: false,
  isSettingsOpen: false,
  isUserLogged: false,
  isLogInLoading: false,
  themeType: savedTheme || "light",
  fmcToken: "",
  isEditing: false,
  loginError: "",
  navigationHistory: [],
  backendEnvironment:
    (localStorage.getItem("backendEnvironment") as TBackendEvironments) ||
    (process.env.REACT_APP_BACKEND_ENV as TBackendEvironments),
  refreshTimeStamp: new Date().getTime(),
  temperatureGraphShowThresholds: false,
  vibrationGraphShowThresholds: false,
  temperatureGraphSelectedRange: {
    fromEpoch: millisecondsToSeconds(subDays(new Date(), 1).valueOf()),
    toEpoch: millisecondsToSeconds(new Date().valueOf()),
  },
  vibrationGraphSelectedRange: {
    fromEpoch: millisecondsToSeconds(subDays(new Date(), 1).valueOf()),
    toEpoch: millisecondsToSeconds(new Date().valueOf()),
  },
  healthGraphSelectedRange: {
    fromEpoch: millisecondsToSeconds(subDays(new Date(), 1).valueOf()),
    toEpoch: millisecondsToSeconds(new Date().valueOf()),
  },
  vibrationGraphUnit: "day",
  temperatureGraphUnit: "day",
  healthGraphUnit: "hour",
  globalGraphUnit: "hour",
  conditionMonitoringLastUpdate: null,
  globalTimespan: 24 * 60,
  globalSelectedRange: {
    fromEpoch: millisecondsToSeconds(subDays(new Date(), 1).valueOf()),
    toEpoch: millisecondsToSeconds(new Date().valueOf()),
  },
  showThresholds: false,
}

const rootSlice = createSlice({
  name: "globals",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(setVibrationTimespan, (state, { payload }) => {
        state.vibrationGraphSelectedRange.fromEpoch =
          /* not sure why this is here, brainfart probably
          payload ??
          */
          // millisecondsToSeconds(
          //   subMinutes(parseISO("2023-05-03"), payload).valueOf(),
          // )
          millisecondsToSeconds(subMinutes(new Date(), payload).valueOf())

        state.vibrationGraphSelectedRange.toEpoch = millisecondsToSeconds(
          new Date().valueOf(),
        )
        state.vibrationGraphUnit = calculateUnit(payload)
      })
      .addCase(setHealthTimespan, (state, { payload }) => {
        state.healthGraphSelectedRange.fromEpoch = millisecondsToSeconds(
          subMinutes(new Date(), payload).valueOf(),
        )
        state.healthGraphSelectedRange.toEpoch = millisecondsToSeconds(
          new Date().valueOf(),
        )
        state.healthGraphUnit = calculateUnit(payload)
      })
      .addCase(setTemperatureTimespan, (state, { payload }) => {
        state.temperatureGraphSelectedRange.fromEpoch = millisecondsToSeconds(
          subMinutes(new Date(), payload).valueOf(),
        )
        state.temperatureGraphSelectedRange.toEpoch = millisecondsToSeconds(
          new Date().valueOf(),
        )
        state.temperatureGraphUnit = calculateUnit(payload)
      })
      .addMatcher(assetsApiSlice.endpoints.getRMS.matchFulfilled, (state) => {
        state.conditionMonitoringLastUpdate = new Date().valueOf()
      })
      .addMatcher(assetsApiSlice.endpoints.getTemp.matchFulfilled, (state) => {
        state.conditionMonitoringLastUpdate = new Date().valueOf()
      })
      .addMatcher(
        assetsApiSlice.endpoints.getVelocity.matchFulfilled,
        (state) => {
          state.conditionMonitoringLastUpdate = new Date().valueOf()
        },
      )
  },
  reducers: {
    setShowTemperatureGraphSelectedRange: (state, action) => {
      state.temperatureGraphShowThresholds = action.payload
    },
    setShowTemperatureGraphThresholds: (state, action) => {
      state.temperatureGraphShowThresholds = action.payload
    },

    setShowVibrationGraphSelectedRange: (state, action) => {
      state.vibrationGraphShowThresholds = action.payload
    },

    setShowVibrationGraphThresholds: (state, action) => {
      state.vibrationGraphShowThresholds = action.payload
    },
    pushNavigationHistory: (state, action) => {
      state.navigationHistory.push(action.payload)
    },
    updateNavigationHistory: (state, action) => {
      state.navigationHistory = action.payload
    },
    setBackendEnvironment: (state, action) => {
      state.backendEnvironment = action.payload
    },
    setRefreshTimeStamp: (state, action) => {
      state.refreshTimeStamp = action.payload
    },
    logOut: () => {
      clearLocalStorageWithExceptions(["themeMode", "backendEnvironment"])
      localStorage.removeItem("persist:root")
      return initialState
    },

    setTheme: (state, action) => {
      state.themeType = action.payload
    },
    setLanguage: (state, action) => {
      state.language = action.payload
    },

    setFmcToken: (state, action) => {
      state.fmcToken = action.payload
    },

    setIsDrawerOpen: (state, action) => {
      state.isDrawerOpen = action.payload
    },

    setIsDrawerDetailsOpen: (state, action) => {
      state.isDrawerDetailsOpen = action.payload
    },

    setIsSettingsOpen: (state, action) => {
      state.isSettingsOpen = action.payload
    },

    setIsUserLogged: (state, action) => {
      state.isUserLogged = action.payload
    },

    setIsLogInLoading: (state, action) => {
      state.isLogInLoading = action.payload
    },

    logIn: (state) => {
      state.isUserLogged = true
      state.isLogInLoading = false
    },

    setLoginError: (state, action) => {
      state.loginError = action.payload
    },

    setIsEditting: (state, action) => {
      state.isEditing = action.payload
    },

    showNotification: (state, action) => {
      notificationType(action.payload)
    },
    setGlobalTimespan: (state, action) => {
      state.globalTimespan = action.payload
      state.globalSelectedRange.fromEpoch = millisecondsToSeconds(
        subMinutes(new Date(), action.payload).valueOf(),
      )
      state.globalSelectedRange.toEpoch = millisecondsToSeconds(
        new Date().valueOf(),
      )
      state.globalGraphUnit = calculateUnit(action.payload)
    },
    setShowThresholds: (state, action) => {
      state.showThresholds = action.payload
    },
  },
})

export const {
  showNotification,
  setBackendEnvironment,
  setFmcToken,

  setIsDrawerOpen,
  setIsDrawerDetailsOpen,
  setIsEditting,
  setIsLogInLoading,
  setIsSettingsOpen,
  setIsUserLogged,
  setLanguage,
  setLoginError,
  setTheme,
  logOut,
  logIn,
  setShowTemperatureGraphSelectedRange,
  setShowVibrationGraphSelectedRange,
  setShowVibrationGraphThresholds,
  pushNavigationHistory,
  updateNavigationHistory,
  setRefreshTimeStamp,
  setShowTemperatureGraphThresholds,
  setGlobalTimespan,
  setShowThresholds,
} = rootSlice.actions

export default rootSlice.reducer
