import React from "react"

import { Typography } from "@mui/material"

import packageJson from "../../../../package.json"

const VersionInfo = () => {
  const { buildNumber } = packageJson ?? 0
  const { version } = packageJson ?? 0

  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {`Version: ${version}, Build: ${buildNumber}`}
    </Typography>
  )
}

export default VersionInfo
