import React from "react"

import AppCardLoader from "core/components/AppCardLoader"
import BaseCard from "core/components/AppCard/BaseCard"
import { ChartOptions } from "chart.js"
import LineChart from "core/components/ChartJS"
import NoDataFound from "core/components/NoDataFound"
import { reformAssetData } from "core/dataActions"
import { RootState } from "store/rootReducer"
import { ROW_HEIGHT } from "core/constants/ui"
import { minutesToMilliseconds, secondsToMilliseconds, toDate } from "date-fns"
import { useAppGetVibrationQuery } from "core/hooks/apiHooks"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { Box, useTheme } from "@mui/material"

import { fontSpecs } from "../../helpers"
import GraphBaseHeader from "./subcomponents/GraphBaseHeader"
import { styles } from "./styles"
import { IDynamicGraphProps } from "types/dynamicGraph"

const labelsScaleFactor = 120

interface IDynamicVibrationGraphProps
  extends Omit<IDynamicGraphProps, "titleCode" | "infoCode" | "iconCode"> {}

const DynamicVibrationGraph = ({
  measureUnitCode: measureUnit,
  assetId: selectedAssetId,
  heightFactor,
  thresholds,
  tooltipText,
  refresh_interval: interval = 5,
}: IDynamicVibrationGraphProps) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { globalSelectedRange: vibrationGraphSelectedRange } = useSelector(
    (state: RootState) => state.global,
  )

  const { assetAxisSelected, assetTypeVelocity } = useSelector(
    (state: RootState) => state.assets,
  )
  const isAcceleration = assetTypeVelocity === "acceleration"

  const [maxTicksGraphLabels, setMaxTicksGraphLabels] = React.useState(
    Math.floor(window.innerWidth / labelsScaleFactor),
  )
  const handleWindowResize = () => {
    setMaxTicksGraphLabels(Math.floor(window.innerWidth / labelsScaleFactor))
  }

  const height = ROW_HEIGHT * heightFactor

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize)
    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [[window.innerWidth]])

  const minDateToDisplay = toDate(
    secondsToMilliseconds(vibrationGraphSelectedRange.fromEpoch),
  ).toISOString()
  const maxDateToDisplay = toDate(
    secondsToMilliseconds(vibrationGraphSelectedRange.toEpoch),
  ).toISOString()

  const graphConfig = {
    scales: {
      x: {
        min: minDateToDisplay,
        max: maxDateToDisplay,
        title: {
          display: false,
        },
        type: "time",

        ticks: {
          font: {
            size: 10,
          },
          color: theme.palette.text.secondary,
          maxTicksLimit: maxTicksGraphLabels,
        },
      },
      y: {
        ticks: {
          font: fontSpecs,
          maxTicksLimit: 6,
        },
        title: {
          font: fontSpecs,
          text: isAcceleration
            ? t("measure.unit.acceleration.label")
            : t("measure.unit.velocity.label"),
        },
      },
    },
  } as ChartOptions<"line">

  const dataSelector = isAcceleration
    ? `rms_${assetAxisSelected}`
    : `velocity_${assetAxisSelected}`

  const {
    vibrationData = [],
    isFetching,
    isError,
    refetch,
  } = useAppGetVibrationQuery(
    {
      assetId: selectedAssetId,
      axis: assetAxisSelected,
      ...vibrationGraphSelectedRange,
    },
    assetTypeVelocity,
    dataSelector,
    {
      skip: !selectedAssetId,
      pollingInterval: minutesToMilliseconds(interval),
    },
  )

  const graphData = reformAssetData(vibrationData)

  const transform = `scale(${heightFactor}) translate(0px,${
    140 + -140 / heightFactor
  }px)`

  if (!vibrationData.length)
    return (
      <BaseCard
        sx={{ ...styles.baseCard, height }}
        customHeader={<GraphBaseHeader tooltipText={tooltipText} />}
      >
        <Box
          sx={{ ...styles.graphContainer, height: height - 50, boxShadow: 0 }}
        >
          <AppCardLoader
            isLoading={isFetching}
            hasError={isError}
            onRetry={refetch}
            style={{
              transform,
            }}
          >
            <NoDataFound
              style={{
                boxShadow: 0,
                transform,
              }}
            />
          </AppCardLoader>
        </Box>
      </BaseCard>
    )

  return (
    <BaseCard
      sx={{ ...styles.baseCard, height }}
      customHeader={<GraphBaseHeader tooltipText={tooltipText} />}
    >
      <Box sx={{ ...styles.graphContainer, height: height - 155 }}>
        <AppCardLoader
          isLoading={isFetching}
          hasError={isError}
          onRetry={refetch}
          style={{
            transform,
          }}
        >
          <LineChart
            datasetLabel={t(isAcceleration ? "acceleration.text" : "velocity")}
            unit={measureUnit}
            zoomEnabled={false}
            config={graphConfig}
            upperBoundOffset={0.5}
            lineColor={theme.palette.primary.main}
            graphData={graphData}
            thresholds={thresholds}
          />
        </AppCardLoader>
      </Box>
    </BaseCard>
  )
}

export default DynamicVibrationGraph
